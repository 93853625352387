<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-subheader>Certificate</v-subheader>
      <v-list-tile
        v-for="schools in school"
        :key="schools.items"
        ripple
      >
        <v-list-tile-content>
          <v-list-tile-title>
            {{ schools.schoolName }}
          </v-list-tile-title>
        </v-list-tile-content>
        <v-btn
          :href="printCert(schools.idSchool)"
          target="_blank"
          fab
          small
          depressed
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams, createGetUrl } from '@/rest'

const pageTitle = 'View And Dowload Certificate'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Certificate', disabled: false, to: '/jombantu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      school: {},
      loading: false,
    }
  },
  mounted: function () {
    this.getSchoolName()
  },

  methods: {
    printCert: function (idSchool) {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl(location.protocol + '//' + location.hostname + '/api/pdf/printJombantuCert.php?idSchool=' + idSchool)
        : createGetUrl('http://203.115.197.112/api/pdf/printJombantuCert.php?idSchool=' + idSchool)
    },
    getSchoolName () {
      this.loading = true
      const params = createGetParams({})
      this.$rest.get('getJomBantuCert.php', params)
        .then((response) => {
          this.school = response.data.items
          console.log(this.school)
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
